export function multilineText(doc, text, x, y, maxWidth, maxRowNum, lineHeigh = 5) {
  let lines = text.split("\n").map(text => {
      return text.split(/(\s)/g) || [] // 按照空格进行切割，但是要保留空格 例：'s s s' 切割结果为 ['s', ' ', 's', ' ', 's']
        // return text.match(/([a-zA-Z0-9'"]+|.)/g) || [];//提取单词，例从"中国,Hello World!"可提取出['中','国',',','Hello',' ','World','!']
    });
    let texts = [];//最终要绘制的多行文本
    for (let words of lines) {
        let sumWidth = 0;
        texts.push("");
        for (let word of words) {
            let width = doc.getTextWidth(word);
            if (width > maxWidth) {//该单词宽度超过最大宽度
                for (let c of word) { //对单词的每一个字符进行判断
                    let mt = doc.getTextWidth(c);
                    sumWidth += mt;
                    if (sumWidth < maxWidth) {//累加的宽度没有超过最大宽度
                        texts[texts.length - 1] += c;
                    } else {//若累加宽度超过最大宽度，重新累加，当前字符另起新行
                        sumWidth = mt;
                        texts.push(c);
                    }
                }
                continue;
            }
            sumWidth += width;
            if (sumWidth < maxWidth) {//累加的宽度没有超过最大宽度
                texts[texts.length - 1] += word;
            } else if (word != " ") {//若累加宽度超过最大宽度，重新累加，当前单词另起新行，注意：空格字符串不用另起新行，忽略掉
                sumWidth = width;
                texts.push(word);
            }
        }
    }
    for (let i = 0; i < texts.slice(0, maxRowNum).length; i++) {
        let text = texts[i];
        if (i + 1 === texts.slice(0, maxRowNum).length && texts.length > maxRowNum) {
          doc.text(text + '...', x, y)
        } else {
          doc.text(text, x, y)
        }
        if (i !== texts.slice(0, maxRowNum).length - 1) {
          y += lineHeigh
        }
      }
    return {
      x,
      y,
      text,
      rowNum: texts.slice(0, maxRowNum).length,
      index: texts.slice(0, maxRowNum).join('').length - 1,
      isLast: texts.length <= maxRowNum
    }
}

export function generateRandomString(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
    characters = characters.slice(0, randomIndex) + characters.slice(randomIndex + 1);
  }

  return result;
}