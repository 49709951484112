<template>
  <div>
    <div class="layout-content">
      <div class="header align-center space-between">
        <div class="title">NEW QUOTE</div>
        <img class="applogo" src="../assets/img/applogo.svg" alt />
        <!-- <div>{{ title }}</div> -->
      </div>
      <div style="flex: 1">
        <nuxt />
      </div>
      <div class="footer">
        <template v-if="!$store.state.auth">
          <a class="cursor-hand" @click="localUrl('/login/login')">Login</a> |
          <a class="cursor-hand" @click="localUrl('/login/register')"
            >Create Account</a
          >
          |
          <a class="cursor-hand" @click="localUrl('/login/forgotPwd')">
            Forgot password
          </a>
          |
        </template>
        <template v-else>
          <a class="cursor-hand" @click="logout">Log out</a> |
          <a class="cursor-hand" @click="linkUrl('/user')">My account</a>
          |
          <a
            class="cursor-hand"
            @click="linkUrl('https://www.rapiddirect.com/about-rapiddirect/')"
          >
            About RaidDirect
          </a>
          |
        </template>
        <a class="cursor-hand" @click="linkUrl('https://www.rapiddirect.com/')">
          Our website
        </a>
        |
        <!-- <a class="cursor-hand" @click="linkUrl('https://app.rapiddirect.com/member/agreement')">
          Terms of service
        </a> -->
        <a class="cursor-hand" @click="linkUrl('https://app.termly.io/document/terms-of-service/658e05ae-a1fc-48e3-8b5a-6e59c7156558')">
          Terms and Conditions
        </a>
        |
         <a class="cursor-hand" @click="linkUrl('https://app.termly.io/document/privacy-policy/383b32a7-2031-4bec-8177-3f251368ce6d')">
          Privacy Policy
        </a>
        |
        <a class="cursor-hand" @click="linkUrl('/quote/quoteList')">
          My quotes
        </a>
        |
        <a class="cursor-hand" @click="linkUrl('/order')">
          My orders
        </a>
        |
        <a class="cursor-hand" @click="linkUrl('/')">
          Our platform
        </a>
      </div>
    </div>
    <dialog-login />
  </div>
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
import dialogLogin from '~/components/dialogLoginNew'

export default {
  components: {
    dialogLogin
  },
  created() {},
  methods: {
    localUrl(url) {
      this.$router.push(url)
    },
    linkUrl(url) {
      window.chrome.webview.hostObjects.bridge.openUrl(
        url.includes('https') ? url : 'https://app.rapiddirect.com' + url
      )
    },
    logout() {
      const data = { userToken: Cookie.get('auth') }
      this.$logout(data).then(res => {
        if (res.code === 1) {
          this.UnreadNum = 0
          this.icon_name = ''
          // 使外部API上的JWT Cookie无效 hteij[{}]
          this.$store.commit('msg/setUnreadNum', 0)
          this.$store.commit('msg/setTeamName', '')
          this.$store.commit('msg/setUserName', '')
          this.$store.commit('msg/setEmail', '')
          Cookie.remove('auth')
          Cookie.remove('isadd')
          Cookie.remove('avatar')
          Cookie.remove('userName')
          this.$store.commit('setAuth', null)
          this.$store.commit('setAvatar', null)
          this.$store.commit('checkQuote', 0)
          localStorage.clear()
          this.$router.push('/')
          sessionStorage.clear()
          // 断开websocket
          // this.$socket.close();
          // this.$store.commit('chatLog/setChatHistory',{count: 0, list: [],room_type: 4})
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-content {
  max-width: 720px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .header {
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .title {
      font-weight: bold;
      color: #000;
    }
  }

  .footer {
    margin-top: 100px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 0 20px;
  }
}
</style>
<style>
/**
  聊天工具弹框
*/
#hubspot-messages-iframe-container.widget-align-right[class='widget-align-right'] {
  display: none !important;
  transform: scale(var(--scale));
  z-index: 8888 !important;
}
</style>