var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "layout-content"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('nuxt')], 1), _vm._v(" "), _c('div', {
    staticClass: "footer"
  }, [!_vm.$store.state.auth ? [_c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.localUrl('/login/login');
      }
    }
  }, [_vm._v("Login")]), _vm._v(" |\n        "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.localUrl('/login/register');
      }
    }
  }, [_vm._v("Create Account")]), _vm._v("\n        |\n        "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.localUrl('/login/forgotPwd');
      }
    }
  }, [_vm._v("\n          Forgot password\n        ")]), _vm._v("\n        |\n      ")] : [_c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Log out")]), _vm._v(" |\n        "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('/user');
      }
    }
  }, [_vm._v("My account")]), _vm._v("\n        |\n        "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('https://www.rapiddirect.com/about-rapiddirect/');
      }
    }
  }, [_vm._v("\n          About RaidDirect\n        ")]), _vm._v("\n        |\n      ")], _vm._v(" "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('https://www.rapiddirect.com/');
      }
    }
  }, [_vm._v("\n        Our website\n      ")]), _vm._v("\n      |\n      "), _vm._v(" "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('https://app.termly.io/document/terms-of-service/658e05ae-a1fc-48e3-8b5a-6e59c7156558');
      }
    }
  }, [_vm._v("\n        Terms and Conditions\n      ")]), _vm._v("\n      |\n       "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('https://app.termly.io/document/privacy-policy/383b32a7-2031-4bec-8177-3f251368ce6d');
      }
    }
  }, [_vm._v("\n        Privacy Policy\n      ")]), _vm._v("\n      |\n      "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('/quote/quoteList');
      }
    }
  }, [_vm._v("\n        My quotes\n      ")]), _vm._v("\n      |\n      "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('/order');
      }
    }
  }, [_vm._v("\n        My orders\n      ")]), _vm._v("\n      |\n      "), _c('a', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('/');
      }
    }
  }, [_vm._v("\n        Our platform\n      ")])], 2)]), _vm._v(" "), _c('dialog-login')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header align-center space-between"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("NEW QUOTE")]), _vm._v(" "), _c('img', {
    staticClass: "applogo",
    attrs: {
      "src": require("../assets/img/applogo.svg"),
      "alt": ""
    }
  })]);

}]

export { render, staticRenderFns }