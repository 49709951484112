<template>
  <div>
    <iframe v-if="!isIframe" :src="$route.path" class="iframe" />
    <nuxt v-else />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isIframe: false,
        url: ''
      }
    },
    mounted() {
      this.url = location.href
      if (!(window.self === window.top)) {
        this.isIframe = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .iframe  {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: rgba(0,0,0,0.1) solid 1px;
    border-radius: 4px;
    width: 375PX;
    height: 667PX;
    background-color: #fff;
  }
</style>

<style>
  #hubspot-messages-iframe-container {
    display: none !important;
  }
</style>