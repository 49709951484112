import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e7e776ea = () => interopDefault(import('..\\pages\\common.js' /* webpackChunkName: "pages/common" */))
const _3ce16ca2 = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _0d46d5eb = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _0614a3da = () => interopDefault(import('..\\pages\\login\\agreement.vue' /* webpackChunkName: "pages/login/agreement" */))
const _2e2faaea = () => interopDefault(import('..\\pages\\login\\forgotPwd.vue' /* webpackChunkName: "pages/login/forgotPwd" */))
const _1ee855b9 = () => interopDefault(import('..\\pages\\login\\login.vue' /* webpackChunkName: "pages/login/login" */))
const _7f1edb20 = () => interopDefault(import('..\\pages\\login\\mailVerify.vue' /* webpackChunkName: "pages/login/mailVerify" */))
const _7d60bc83 = () => interopDefault(import('..\\pages\\login\\register.vue' /* webpackChunkName: "pages/login/register" */))
const _19f71a4c = () => interopDefault(import('..\\pages\\login\\resetPassword.vue' /* webpackChunkName: "pages/login/resetPassword" */))
const _02153a12 = () => interopDefault(import('..\\pages\\quote\\mixins\\index.js' /* webpackChunkName: "pages/quote/mixins/index" */))
const _5e2ba320 = () => interopDefault(import('..\\pages\\quote\\success.vue' /* webpackChunkName: "pages/quote/success" */))
const _2576026d = () => interopDefault(import('..\\pages\\quote\\components\\configure.vue' /* webpackChunkName: "pages/quote/components/configure" */))
const _0c7fd226 = () => interopDefault(import('..\\pages\\quote\\components\\summary.vue' /* webpackChunkName: "pages/quote/components/summary" */))
const _fc60c8ca = () => interopDefault(import('..\\pages\\quote\\mixins\\configComputed.js' /* webpackChunkName: "pages/quote/mixins/configComputed" */))
const _a463ba12 = () => interopDefault(import('..\\pages\\quote\\mixins\\imgagePrice.js' /* webpackChunkName: "pages/quote/mixins/imgagePrice" */))
const _0de2be76 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexComputed.js' /* webpackChunkName: "pages/quote/mixins/indexComputed" */))
const _c2e376f0 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexData.js' /* webpackChunkName: "pages/quote/mixins/indexData" */))
const _166290aa = () => interopDefault(import('..\\pages\\quote\\mixins\\indexMethods.js' /* webpackChunkName: "pages/quote/mixins/indexMethods" */))
const _54920bcc = () => interopDefault(import('..\\pages\\quote\\mixins\\publicMixin.js' /* webpackChunkName: "pages/quote/mixins/publicMixin" */))
const _47b4966d = () => interopDefault(import('..\\pages\\quote\\mixins\\saveBtnValid.js' /* webpackChunkName: "pages/quote/mixins/saveBtnValid" */))
const _972cba6e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _106cb0b1 = () => interopDefault(import('..\\pages\\index' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/common",
    component: _e7e776ea,
    name: "common"
  }, {
    path: "/quote",
    component: _3ce16ca2,
    name: "quote"
  }, {
    path: "/test",
    component: _0d46d5eb,
    name: "test"
  }, {
    path: "/login/agreement",
    component: _0614a3da,
    name: "login-agreement"
  }, {
    path: "/login/forgotPwd",
    component: _2e2faaea,
    name: "login-forgotPwd"
  }, {
    path: "/login/login",
    component: _1ee855b9,
    name: "login-login"
  }, {
    path: "/login/mailVerify",
    component: _7f1edb20,
    name: "login-mailVerify"
  }, {
    path: "/login/register",
    component: _7d60bc83,
    name: "login-register"
  }, {
    path: "/login/resetPassword",
    component: _19f71a4c,
    name: "login-resetPassword"
  }, {
    path: "/quote/mixins",
    component: _02153a12,
    name: "quote-mixins"
  }, {
    path: "/quote/success",
    component: _5e2ba320,
    name: "quote-success"
  }, {
    path: "/quote/components/configure",
    component: _2576026d,
    name: "quote-components-configure"
  }, {
    path: "/quote/components/summary",
    component: _0c7fd226,
    name: "quote-components-summary"
  }, {
    path: "/quote/mixins/configComputed",
    component: _fc60c8ca,
    name: "quote-mixins-configComputed"
  }, {
    path: "/quote/mixins/imgagePrice",
    component: _a463ba12,
    name: "quote-mixins-imgagePrice"
  }, {
    path: "/quote/mixins/indexComputed",
    component: _0de2be76,
    name: "quote-mixins-indexComputed"
  }, {
    path: "/quote/mixins/indexData",
    component: _c2e376f0,
    name: "quote-mixins-indexData"
  }, {
    path: "/quote/mixins/indexMethods",
    component: _166290aa,
    name: "quote-mixins-indexMethods"
  }, {
    path: "/quote/mixins/publicMixin",
    component: _54920bcc,
    name: "quote-mixins-publicMixin"
  }, {
    path: "/quote/mixins/saveBtnValid",
    component: _47b4966d,
    name: "quote-mixins-saveBtnValid"
  }, {
    path: "/",
    component: _972cba6e,
    name: "index"
  }, {
    path: "/",
    component: _106cb0b1,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
