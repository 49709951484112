import Vue from 'vue'

Vue.filter('formatDate_en', value => {
  if (!value) return ''
  var dt = new Date(value * 1000)
  var mArr = new Array(
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Spt',
    'Oct',
    'Nov',
    'Dec'
  )
  const m = dt.getMonth()
  const y = dt.getFullYear()
  const d = dt.getDate()
  return mArr[m] + ' ' + d + ',' + y + '(GTM+8)'
})
Vue.filter('formatDate', (date, fmt) => {
  if (!fmt) {
    fmt = 'yyyy-MM-dd hh:mm:ss'
  }
  date = new Date(date)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  }
  return fmt
})
Vue.filter('formatPrice', vaule => {
  if (!vaule) return
  // 千分位分隔符
  return Number(vaule).toLocaleString()
})
Vue.filter('getSuffix', value => {
  let type = 7
  if (/(.step|.stl|.stp|.prt)$/.test(value)) {
    type = 1
  } else if (/(.jpg|.png|.webp|.jpeg)$/.test(value)) {
    type = 6
  } else if (/.pdf$/i.test(value)) {
    type = 2
  } else if (/(.xlsx|.xls)$/i.test(value)) {
    type = 3
  } else if (/.ppt$/i.test(value)) {
    type = 5
  }
  return type
})
