<template>
  <div class="address-win">
    <el-dialog :visible.sync="dialogLogin" :close-on-click-modal="false">
      <div class="dialog" @click="close"></div>
      <div class="dialog-win">
        <div class="dialog-close" @click="close">
          <i class="el-icon-close"></i>
        </div>
        <div class="login-title">
          <p>Welcome Back</p>
          <p class="subTitle">to RapidDirect online platform</p>
        </div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item prop="email">
            <div class="aleady_input">
              <label class="label">
                <span class="span_1">Email</span>
              </label>
              <el-input v-model="ruleForm.email" placeholder="Email"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="password" prop="password">
            <div class="aleady_input">
              <label class="label">
                <span class="span_1">password</span>
              </label>
              <el-input
                v-model="ruleForm.password"
                type="password"
                placeholder="Password"
                show-password
              ></el-input>
            </div>
          </el-form-item>
          <div>
            <p class="cfont font12 cursor-underline" @click="findPass">Forgot your password?</p>
          </div>
          <el-form-item>
            <el-button
              class="loginBtn"
              type="primary"
              size="large"
              :loading="loginLoading"
              @click="login('ruleForm')"
            >Log in</el-button>
          </el-form-item>
          <el-form-item>
            <div class="dialog-mess">
              <span>Don't have an account?</span>
              <span class="cfont fwb cursor-underline" @click="signUp">Sign Up</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  name: 'DialogLogin',
  middleware: 'authenticated',
  data() {
    return {
      loginLoading: false,
      ruleForm: {
        email: '',
        password: '',
      },
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0,
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please enter your password',
            trigger: 'blur',
          },
        ],
      },
      dialogLogin: false,
    }
  },
  watch: {
    '$store.state.dialogLogin': function (val) {
      this.dialogLogin = val
      this.ruleForm.email = localStorage.getItem('mail') || ''
    },
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true
          const data = {
            user: this.ruleForm.email,
            password: this.ruleForm.password,
          }
          this.$login(data).then((res) => {
            this.loginLoading = false
            if (res.code === 1) {
              this.$notify({
                type: 'success',
                title: 'Success',
                customClass: 'notify-success',
                message: 'Login successfully',
              })
              const auth = res.data.token
              const isAdd = res.data.address_id
              this.$store.commit('msg/setTeamName', res.data.team)
              this.$store.commit('setAuth', auth) // 存储在vuex中用来进行客户端渲染
              localStorage.setItem('emailValidated', res.data.email_validated)
              const expires = new Date(new Date() * 1 + 86400000 * 30) // 有效期一个月
              Cookie.set('auth', auth, { expires: expires }) // 在cookie中保存token用来进行服务器端渲染
              Cookie.set('isadd', isAdd, { expires: expires })
              const checkQuote = res.data.check_quote_status
              this.$store.commit('checkQuote', checkQuote)
              Cookie.set('checkQuote', checkQuote, { expires: expires })
              this.getMsg()
              this.$getUser().then((res) => {
                if (res.code === 1) {
                  localStorage.setItem('mail', res.data.email)
                  localStorage.setItem('email', res.data.email)
                  localStorage.setItem('userName', res.data.userName)
                  this.$store.commit('setAvatar', res.data.imageUrl)
                  this.$store.commit('msg/setUserName', res.data.userName)
                  this.$store.commit('msg/setEmail', res.data.email)
                  this.$store.commit('msg/setFirstName', res.data.firstName)
                  this.$store.commit('msg/setLastName', res.data.lastName)
                  const loginType = this.$store.state.loginType
                  this.$store.commit('openLogin', false) // 登录成功关闭弹窗
                  this.$store.commit('loginOk', loginType) // 登录成功返回状态
                  Cookie.set('avatar', res.data.imageUrl, {
                    expires: expires,
                    domain: this.$store.state.msg.url,
                  })
                  Cookie.set('userName', res.data.userName, {
                    expires: expires,
                    domain: this.$store.state.msg.url,
                  })
                  // location.reload()
                }
              })
              window.setLayer({ event: 'RDLoginSuccess', dimension1: 'login' })
            } else {
              this.$notify.error({
                title: 'Error',
                customClass: 'notify-error',
                message: 'Incorrect user name or password',
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取信息角标
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10,
      }
      this.$getMsg(data).then((res) => {
        this.$store.commit('msg/setUnreadNum', res.data.unread)
      })
    },
    close() {
      this.$store.commit('openLogin', false)
      let home = false
      const rt = this.$route.path
      rt === '/' ||
      rt === '/member/findPassword' ||
      rt === '/member/resetPassword'
        ? (home = false)
        : (home = true)
      if (home) {
        this.$router.push('/')
      }
    },
    signUp() {
      this.$store.commit('openLogin', false)
      this.$store.commit('openRegister', true)
    },
    findPass() {
      this.$store.commit('openLogin', false)
      this.$store.commit('openFindPassword', true)
    },
  },
}
</script>
<style scoped lang="scss">
.dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: $c1B1C21;
  z-index: 1010;
}
.dialog-win {
  position: fixed;
  right: 0;
  top: 0;
  width: 644px;
  height: 100vh;
  background: #f4f4f8;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1013;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 37px;
  right: 54px;
  width: 32px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  color: $white;
  line-height: 32px;
  background: $c1B1C21;
  border-radius: 10px;
  cursor: pointer;
}
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.login-title {
  padding: 20vh 0 5vh 66px;
  background: $white;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: $c1B1C21;
  .subTitle {
    font-size: 14px;
  }
}
.demo-ruleForm {
  padding: 54px 54px 0;
  background: #f4f4f8;
}
.loginBtn {
  font-size: 14px;
  width: 100%;
  height: 56px;
  font-weight: bold;
  margin-top: 15px;
}
.dialog-mess {
  color: #999;
  text-align: center;
}
.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
}
.aleady_input {
  position: relative;
  background: #fff;
  border: solid 1px #f4f4f8;
  border-radius: 8px;
  line-height: 1;
  .label {
    display: none;
    padding: 4px 12px 0;
    height: 16px;
    color: #b1bacc;
    font-size: 12px;
  }
  &:focus-within {
    border: solid 1px #c8ceda;
    .label {
      display: block;
    }
  }
  /deep/ .el-input {
    font-size: 14px;
    color: #8c93a1;
    font-weight: 700;
    .el-input__inner {
      height: 56px;
      background: none;
      border: none;
      &:focus {
        height: 36px;
      }
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgb(151, 151, 151);
      font-size: 12px;
      font-weight: 400;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgb(151, 151, 151);
      font-size: 12px;
      font-weight: bolder;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgb(151, 151, 151);
      font-size: 14px;
      font-weight: bolder;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgb(151, 151, 151);
      font-size: 12px;
      font-weight: bolder;
    }
  }
}
</style>
