var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-main', [_vm.error.statusCode === 404 ? _c('el-row', {
    attrs: {
      "type": "flex"
    }
  }, [_c('el-col', [_c('h1', [_vm._v("Oops!")]), _vm._v(" "), _c('p', [_vm._v("We could not find the page you were looking for")]), _vm._v(" "), _c('nuxt-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    }
  }, [_vm._v("Go Back Home")])], 1)], 1), _vm._v(" "), _c('el-col', {
    staticClass: "tc"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/404.png"),
      "alt": "404"
    }
  })])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }