const Cookie = process.client ? require('js-cookie') : undefined
export default function(app) {
  const axios = app.$axios
  // 基本配置
  axios.defaults.timeout = 120000
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  // 请求错误时重新发起请求
  axios.defaults.retry = 0 // 重试次数
  axios.defaults.retryDelay = 3000 // 重试延时
  axios.defaults.shouldRetry = error => {
    if (error) {
      return true
    }
  } // 重试条件

  // 请求回调
  axios.onRequest(config => {
    if (!config.baseURL) {
      config.baseURL = '/api'
    }
    return config
  })

  // 返回回调
  axios.onResponse(res => {
    // 拦截器配置
    // 用户未登录或登录已过期
    if (res.data.code === -23 || res.data.code === -996) {
      let home = false
      const rt = app.route.path
      rt === '/' ||
      rt === '/member/findPassword' ||
      rt === '/member/resetPassword' ||
      rt === '/member/agreement' ||
      rt === '/member/login' ||
      rt === '/member/register'
        ? (home = false)
        : (home = true)
      Cookie.remove('auth')
      app.store.commit('setAuth', null)
      app.store.commit('checkQuote', 0)
      app.store.commit('setLoginType', 'aside')
      if (!location.pathname.includes('login')) {
        window.location.href =
          location.origin +
          '/login/login?redirect=' +
          encodeURIComponent(location.pathname + location.search)
      }
      // return app.store
      //   .dispatch('wantLogin', { visible: home, loginType: 'openLogin' })
      //   .then(() => {
      //     Object.defineProperties(res.config, {
      //       baseURL: {
      //         value: '',
      //         writable: true
      //       }
      //     })
      //     console.log(res.config, 123213)
      //     return axios(res.config).then(ress => {
      //       return ress
      //     })
      //   })
    } else if (res.data.code === -19) {
      window.location.pathname = '/error'
    } else if (['/api/64097ee2d10bf'].includes(res.config.url)) {
      return {
        headers: res.headers,
        data: res.data
      }
    } else {
      return res.data // data数据
    }
    // return res // 全部数据
  })

  // 错误回调
  axios.onError(error => {
    const config = error.config
    Object.defineProperties(config, {
      baseURL: {
        value: '',
        writable: true
      }
    })
    // 判断是否配置了重试
    if (!config || !config.retry) {
      return Promise.reject(error)
    }
    if (!config.shouldRetry || typeof config.shouldRetry !== 'function') {
      return Promise.reject(error)
    }

    // 判断是否满足重试条件
    if (!config.shouldRetry(error)) {
      return Promise.reject(error)
    }

    // 设置重置次数，默认为0
    config.__retryCount = config.__retryCount || 0

    // 判断是否超过了重试次数
    if (config.__retryCount >= config.retry) {
      return Promise.reject(error)
    }

    // 重试次数自增
    config.__retryCount += 1

    // 延时处理
    const backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve()
      }, config.retryDelay || 1)
    })

    // 重新发起axios请求
    return backoff.then(function() {
      return axios(config)
    })
  })
}
