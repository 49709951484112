var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isIframe ? _c('iframe', {
    staticClass: "iframe",
    attrs: {
      "src": _vm.$route.path
    }
  }) : _c('nuxt')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }